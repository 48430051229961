<template>
    <ModalBox
        v-if="webmaster"
        :is-active.sync="isModalActive">
        <template #header>
            <h5 class="is-5 title">
                {{ $t(`admin.users.webmasters.modal.details.header.title`) }}
                {{ webmaster.login }} [{{ webmaster.intId }}]
            </h5>
        </template>
        
        <WebmastersTableDetailsModalDetails
            class="mb-6"
            :is-active="isModalActive"
            :webmaster="webmaster">
        </WebmastersTableDetailsModalDetails>
        <WebmastersTableDetailsModalIndividualRate
            :is-active="isModalActive"
            :webmaster="webmaster">
        </WebmastersTableDetailsModalIndividualRate>
    </ModalBox>
</template>

<script>
  import { Modal } from "@core/mixins";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import WebmastersTableDetailsModalDetails from "./WebmastersTableDetailsModal/WebmastersTableDetailsModalDetails";
  import WebmastersTableDetailsModalIndividualRate from "./WebmastersTableDetailsModal/WebmastersTableDetailsModalIndividualRate";
  
  export default {
    name: "WebmastersTableDetailsModal",
    mixins: [Modal],
    components: { WebmastersTableDetailsModalDetails, WebmastersTableDetailsModalIndividualRate, ModalBox },
    permissions: [
      "WEBMASTERS.VIEW_INDIVIDUAL_RATES.ALL",
      "WEBMASTERS.VIEW_INDIVIDUAL_RATES.OWN"
    ],
    props: {
      webmaster: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style lang="scss" scoped>
    .modal {
        ::v-deep {
            .animation-content {
                max-width: 1600px !important;
                width: calc(100vw - 40px);
            }
        
            .modal-card {
                max-width: 100%;
                width: auto;
            
                .modal-card-foot {
                    padding: 7.5px;
                }
            }
        }
    }
</style>
