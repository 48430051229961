<template>
    <LFilters
        ref="filters"
        :filters="webmastersFilters"
        :advanced-filters="advancedFilters"
        :ignored-values="ignoredValues"
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <WebmastersFiltersMain></WebmastersFiltersMain>
        </template>

        <template #advanced-filters>
            <WebmastersFiltersOptions></WebmastersFiltersOptions>
        </template>
    </LFilters>
</template>

<script>
  import { GET_WEBMASTERS, SET_EMPTY } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import LFilters from "@/components/Common/LFilters";
  import WebmastersFiltersMain from "@/components/Admin/Users/WebmastersTab/WebmastersFiltersMain";
  import WebmastersFiltersOptions from "@/components/Admin/Users/WebmastersTab/WebmastersFiltersOptions";
  import webmastersFields from "./datasets/webmastersFields.json";
  import { UPDATE_WEBMASTERS_FILTERS } from "@core/store/mutation-constants";
  import moment from "moment";
  import { momentToDate } from "@core/helpers/momentToDate";
  import momentjs from "moment/moment";

  export default {
    name: "WebmastersFilters",
    components: {
      WebmastersFiltersOptions,
      WebmastersFiltersMain,
      LFilters
    },

    computed: {
      ...mapState("admin/users/webmasters",{
        webmastersFilters: state => state.filters
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      advancedFilters () {
        return webmastersFields.advancedFilters;
      },

      ignoredValues () {
        return {
          registeredDatepicker: {
            registeredAtStart: null,
            registeredAtEnd: moment(momentToDate(momentjs().endOf("day"))).format("YYYY-MM-DDTHH:mm:ssZ")
          }
        };
      }
    },

    methods: {
      ...mapActions("admin/users/webmasters", {
        SET_EMPTY,
        GET_WEBMASTERS,
        UPDATE_WEBMASTERS_FILTERS
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_WEBMASTERS_FILTERS(filters);
        this.GET_WEBMASTERS();
      }
    },

    watch: {
      currentVertical () {
        this.$refs.filters.clear();
        this.GET_WEBMASTERS();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .field.is-pulled-right {
      display: contents;
    }
  }
</style>
