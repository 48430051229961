<template>
    <ModalBox
        :is-active="isModalActive"
        @close="close">
        <template #header>
            <h5 class="title is-5">
                {{ $t("admin.users.webmasters.modal.referrals.header.title") }}
            </h5>
        </template>

        <div class="is-flex is-align-items-center mb-4">
            <b class="is-6 mr-2 nowrap">
                {{ $t("admin.users.webmasters.modal.referrals.body.refer.title") }}
            </b>
            <UserOutput
                v-if="referrer && !isLoading.refer"
                :user="referrer">
            </UserOutput>

            <span
                v-if="!referrer && !isLoading.refer"
                class="empty">
                {{ $t("admin.users.webmasters.modal.referrals.body.refer.empty") }}
            </span>

            <b-skeleton
                v-if="isLoading.refer"
                width="60px"
                height="25px"
                animated>
            </b-skeleton>
        </div>

        <b-table
            :data="referrals || []"
            :loading="isLoading.referrals">
            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.users.webmasters.modal.referrals.body.labels.id`)">
                {{ formatEmptyString(row.intId) }}
            </b-table-column>

            <b-table-column
                v-slot="{ row }"
                centered
                :label="$t(`admin.users.webmasters.modal.referrals.body.labels.referral`)">
                {{ formatEmptyString(row.login) }}
            </b-table-column>

            <b-table-column
                v-if="hasToggleReferral"
                v-slot="{ row: { canBeReferral, id } }"
                width="25%"
                centered>
                <LTooltip
                    :label="$t(`admin.users.webmasters.modal.referrals.body.details.${ !canBeReferral }`)"
                    position="is-left"
                    :type="`is-${ !canBeReferral ? 'success' : 'danger' }`">
                    <b-button
                        :icon-left="`${ !canBeReferral ? 'check' : 'times' }-circle`"
                        icon-pack="fas"
                        :loading="isLoading.button"
                        rounded
                        size="is-small"
                        :type="`is-${ !canBeReferral ? 'success' : 'danger' } is-light`"
                        @click="actionReferral({ id, canBeReferral })">
                    </b-button>
                </LTooltip>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">
                    <h2 class="is-size-5 p-3 is-bold has-text-weight-medium has-text-grey">
                        {{ $t("admin.users.webmasters.modal.referrals.body.value.empty") }}
                    </h2>
                </div>
            </template>
        </b-table>
    </ModalBox>
</template>

<script>
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
  import {
    DiSABLE_REFERRAL,
    ENABLE_REFERRAL,
    GET_REFERRALS,
    GET_REFERRER,
    SET_EMPTY
  } from "@core/store/action-constants";
  import { hasPermissions } from "@core/mixins/permissions";
  import { formatEmptyString } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import { actionWithToast } from "@/helpers/actionWithToast";

  export default {
    name: "WebmastersTableReferralModal",

    components: {
      UserOutput,
      ModalBox,
      LTooltip
    },

    computed: {
      ...mapState({
        hasToggleReferral: ({ auth }) => hasPermissions(["ADMIN.TOGGLE_CAN_BE_REFERRAL"], auth.userPermissions)
      }),

      ...mapState("admin/users/webmasters/referralsModal", {
        isModalActive: state => state.isModalActive,
        referrals: state => state.referrals?.items,
        referrer: state => state.referrer
      }),

      isLoading () {
        const path = "admin/users/webmasters/referralsModal";

        return {
          referrals: this.$wait(`${ path }/${ GET_REFERRALS }`),
          refer: this.$wait(`${ path }/${ GET_REFERRER }`),
          button: this.$wait(`${ path }/${ DiSABLE_REFERRAL }`) ||
            this.$wait(`${ path }/${ ENABLE_REFERRAL }`)
        };
      }
    },

    methods: {
      formatEmptyString,

      ...mapActions("admin/users/webmasters/referralsModal", [
        DiSABLE_REFERRAL,
        ENABLE_REFERRAL,
        GET_REFERRALS,
        GET_REFERRER,
        SET_EMPTY
      ]),

      close () {
        this.SET_EMPTY();
      },

      async actionReferral ({ id, canBeReferral }) {
        const dataSet = {
          "true": { action: this.DiSABLE_REFERRAL, text: "disable" },
          "false": { action: this.ENABLE_REFERRAL, text: "enable" }
        };
        await actionWithToast(
          dataSet[canBeReferral].action(id),
          `admin.users.webmasters.modal.referrals.messages.${ dataSet[canBeReferral].text }`
        );
      }
    }
  };
</script>

<style lang="scss" scoped>
    .b-skeleton {
      width: initial;
    }

    .empty {
        line-height: 1.2;
    }

    ::v-deep {
        .table {
            th {
                &:first-child {
                    border-radius: 5px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 5px 0 0;
                }
            }
        }
    }
</style>
