<template>
    <th
        v-if="isHeader"
        :class="rowField.titleClass"
        @click="onColumnHeaderClicked(rowField, $event)">
        <!--        rowData: {{ rowData }}-->
        <!--        rowIndex: {{ rowIndex }}-->
        <!--        rowField: {{ rowField }}-->
        <!--        isHeader: {{ isHeader }}-->
        <!--        title: {{ title }}-->
        <div v-html="title"></div>
    </th>
    <td
        v-else-if="!isTotalRow"
        :class="rowField.dataClass">
        <template>
            <Countries
                :items="countries"
                :max-height="calcCountryHeight"
                compact
                tooltip-position="is-top">
            </Countries>
        </template>
    </td>
    <td
        v-else-if="isTotalRow"
        :colspan="groupsAmount"
        :class="[rowField.dataClass, { 'has-background-warning-light': isTotalRow }]">
        <h6
            v-t="rowData.label"
            class="title is-6"></h6>
    </td>
</template>

<script>
  import { StatisticTableView } from "@core/mixins";
  import Countries from "@/components/Common/Lists/Countries.vue";
  
  export default {
    name: "StatisticTableViewCountry",
    mixins: [StatisticTableView],
    components: { Countries },
    computed: {
      calcCountryHeight () {
        const height = (this.rowIndex + 1) * 40 + 80;
        return Math.min(height, 200);
      },
      
      countries () {
        const items = this.getMethodByPath(this.rowData, this.rowField.label);
        
        if (!items.length) {
          return [];
        }
        return items;
      },
      
      name () {
        return this.rowField.label.split(".")[1];
      },
      
      isTotalRow () {
        return this.rowData?.total?.isTotal;
      },
      
      isTotalColumn () {
        return this.rowField?.isTotal;
      },
      
      groupsAmount () {
        return this.vuetable.tableFields.reduce((acc, item) => {
          if (item.label.startsWith("groups")) {
            return ++acc;
          } else {
            return acc;
          }
        }, 0);
      }
    },
    
    methods: {
      formattedGetMethodByPath (label) {
        return this.formatter(this.getMethodByPath(this.rowData, label));
      }
    }
  };
</script>

<style lang="scss" scoped>
  th {
    & > div {
      display: flex;
      white-space: nowrap;
      
      ::v-deep {
        i {
          margin-left: 0.5em;
        }
      }
    }
  }
</style>
