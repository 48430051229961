<template>
    <ModalBox :is-active.sync="isModalActive">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.users.webmasters.modal.bindUser.header.title`) }}
            </h5>
        </template>
        <b-field expanded>
            <div class="columns is-variable is-1">
                <div class="column">
                    <ManagersSelect
                        event="select"
                        :value="selectedAdminIds"
                        :loading="isLocalLoading"
                        :multiple="role === 'webmasters'"
                        :sort-options="{ sort: 'isBlocked', order: 'ASC' }"
                        :selectable="() => selectedAdminIds.length < 2"
                        namespace-module="admin/users/webmasters/managersFilters"
                        :placeholder="$t(`admin.users.webmasters.modal.bindUser.body.labels.admin`)"
                        @select="onSelectAdmin">
                    </ManagersSelect>
                </div>
                <div
                    v-if="removable"
                    class="column is-narrow">
                    <b-button
                        type="is-danger is-light"
                        icon-right="times"
                        @click="unbindAdmin">
                    </b-button>
                </div>
            </div>
        </b-field>
        <template #footer>
            <div class="container">
                <div class="columns is-pulled-right">
                    <div class="buttons column is-4">
                        <b-button
                            :disabled="isAlreadyBind || isLocalLoading"
                            type="is-success"
                            @click="bindAdmin">
                            <template v-if="isAlreadyBind">
                                {{ $t("admin.users.common.messages.bind.alreadyBind") }}
                                <span
                                    :key="idx"
                                    v-for="(item, idx) in admin">
                                    {{ item.login }}
                                </span>
                            </template>
                            <template v-else>
                                <template v-if="selectedAdmins.length">
                                    {{ $t("common.buttons.bind") }}
                                    <span
                                        :key="idx"
                                        v-for="(item, idx) in selectedAdmins">
                                        {{
                                            item.login
                                        }}<template v-if="selectedAdmins.length - 1 !== idx">,</template>
                                    </span>
                                </template>
                                <template v-else>
                                    {{ $t("admin.users.webmasters.modal.bindUser.body.labels.bindToSelf") }}
                                </template>
                            </template>
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ManagersSelect from "@/components/Common/Select/ManagersSelect.vue";
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { Modal } from "@core/mixins";
  import { mapState } from "vuex";
  import { useBadges } from "@/stores/common/badgesStore";
  import { BadgesEnum } from "@core/store/types/common/enums/badgesEnum";
  
  export default {
    name: "ModalBindUserToAdmin",
    mixins: [Modal],
    components: {
      ModalBox,
      ManagersSelect
    },

    props: {
      method: {
        // Метод, которые будет использоваться для бинда админа
        // (потому что у разных типов пользователей могут быть разные метода)
        type: Function,
        required: true
      },
      deleteMethod: {
        type: Function,
        required: true
      },
      userId: {
        type: [String, null],
        required: true
      },
      userLogin: {
        type: [String, null],
        required: true
      },
      admin: {
        type: Array,
        default: () => []
      },
      role: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        selectedAdminIds: this.admin.map(item => item.id),
        selectedAdmins: this.admin
      };
    },

    setup () {
      return {
        badgesStore: useBadges([BadgesEnum.WEBMASTERS])
      };
    },

    computed: {
      ...mapState("admin", {
        adminUserIdState: state => state.profile.user.id
      }),

      adminIds () {
        return this.selectedAdminIds.length !== 0 ? this.selectedAdminIds : [this.adminUserIdState];
      },

      removable () {
        return this.admin[0]?.id != null;
      },

      isAlreadyBind () {
        const adminIsd = this.admin.map(item => item.id);
        const arrIds = this.selectedAdminIds.map(item => adminIsd.includes(item));
        if (this.selectedAdminIds.length < this.admin.length || this.selectedAdminIds.length === 0) {
          return false;
        } else return arrIds.every(item => item);
      },
  
      isLocalLoading () {
        return this.isLoading;
      }
    },

    methods: {
      unbindAdmin () {
        this.$buefy.dialog.confirm({
          title:  this.$t(`admin.users.webmasters.confirm.${ this.role === "webmasters" ? "isBoundWeb" : "isBoundRekl" }`),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-success is-light",
          onConfirm: async () => {
            try {
              await this.deleteMethod(this.userId);
              this.emitUpdate();
              this.toastSuccess(this.$t("admin.users.common.messages.unbind.success"));
              this.closeModal();
              await this.badgesStore.GET_BADGES();
            } catch (err) {
              this.toastFail(this.$t("admin.users.common.messages.unbind.fail"));
            }
          }
        });
      },

      async bindAdmin () {
        const { userId, adminIds, userLogin } = this;

        try {
          await this.method({ userId, adminIds });
          this.emitUpdate();
          this.toastSuccess(this.$t(`admin.users.${ this.role }.messages.bind.success`, { userLogin }));
          this.closeModal();
          await this.badgesStore.GET_BADGES();
        } catch (err) {
          this.toastFail(this.$t(`admin.users.${ this.role }.messages.bind.fail`, { userLogin }), err);
        }
      },
  
      onSelectAdmin (option) {
        const arrayAdmins = option ? Array.isArray(option) ? option : [option] : [];
        this.selectedAdminIds = arrayAdmins.map(item => item.id);
        this.selectedAdmins = arrayAdmins;
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card, .modal-card-body {
      overflow: visible;
    }

    .modal-card-foot {
      border-radius: 0 0 10px 10px;
    }

    .modal-card-head {
      border-radius: 10px 10px 0 0;
    }
  }
</style>
