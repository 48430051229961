<template>
    <div
        class="mr-2"
        @click.stop>
        <LTooltip
            :label="$t(`common.preferredCurrency.${ _isVisiblePreferredCurrency }`)"
            position="is-top">
            <b-checkbox-button
                :value="_isVisiblePreferredCurrency"
                class="checkbox-preferred-currency"
                @input="togglePreferredCurrency">
                {{ currencySign(preferredCurrency) }}
            </b-checkbox-button>
        </LTooltip>
    </div>
</template>

<script>
  import { currencySign } from "@core/filters";

  export default {
    name: "PreferredCurrencyCheckbox",
    props: {
      isVisiblePreferredCurrency: {
        type: Boolean,
        default: true
      },
      nameLocalStorage: {
        type: String,
        default: null
      }
    },
    created () {
      const preferredCurrency = localStorage.getItem(this.nameLocalStorage) ?? "USD";
      if (preferredCurrency) {
        this._isVisiblePreferredCurrency = preferredCurrency === "true";
      }
    },
    computed: {
      preferredCurrency () {
        return this.$store.state.auth.preferredCurrency;
      },

      _isVisiblePreferredCurrency: {
        get () {
          return this.isVisiblePreferredCurrency;
        },
        set (value) {
          this.$emit("update:isVisiblePreferredCurrency", value);
        }
      }
    },
    methods: {
      currencySign,
        
      togglePreferredCurrency (value) {
        this._isVisiblePreferredCurrency = value;
        localStorage.setItem(this.nameLocalStorage, value);
      }
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .checkbox-preferred-currency label {
            padding: 0;
            width: 25px;
            height: 25px;
        }
    }
</style>
