import { render, staticRenderFns } from "./StatisticTableViewNumber.vue?vue&type=template&id=f51b8cde&scoped=true"
import script from "./StatisticTableViewNumber.vue?vue&type=script&lang=js"
export * from "./StatisticTableViewNumber.vue?vue&type=script&lang=js"
import style0 from "./StatisticTableViewNumber.vue?vue&type=style&index=0&id=f51b8cde&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f51b8cde",
  null
  
)

export default component.exports