<template>
    <div>
        <IndividualRateFilters></IndividualRateFilters>
        <IndividualRateTable @sort="GET_WEBMASTER_RATES">
        </IndividualRateTable>
    </div>
</template>

<script>
  import { DebounceUpdateByWatchedParams, FilteredComponent } from "@core/mixins";
  import { mapActions, mapState } from "vuex";
  import IndividualRateFilters from "./WebmastersTableDetailsModalIndividualRate/IndividualRateFilters";
  import IndividualRateTable from "./WebmastersTableDetailsModalIndividualRate/IndividualRateTable";
  import {
    GET_WEBMASTER_RATES,
    UPDATE_WEBMASTER_RATES_FILTERS,
    SET_EMPTY
  } from "@core/store/action-constants";

  export default {
    name: "WebmastersTableDetailsModalIndividualRate",
    mixins: [FilteredComponent, DebounceUpdateByWatchedParams],
    components: {
      IndividualRateTable,
      IndividualRateFilters
    },
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    },
    created () {
      const {
        offer,
        country
      } = this.$route.query;

      const filters = {
        ...offer && { offer },
        ...country && { country }
      };

      this.UPDATE_WEBMASTER_RATES_FILTERS(filters);
    },
    computed: {
      ...mapState("admin/users/webmasters/detail/rates", {
        updateParams: ({ filters, pagination }) => ({ ...filters, ...pagination })
      })
    },
    methods: {
      ...mapActions("admin/users/webmasters/detail/rates", {
        GET_WEBMASTER_RATES,
        UPDATE_WEBMASTER_RATES_FILTERS,
        SET_EMPTY
      }),

      async updated () {
        if (this.isActive) {
          await this.GET_WEBMASTER_RATES();
        }
      }
    },
    watch: {
      isActive (value) {
        if (value) this.updated();
      }
    },
    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped>

</style>
