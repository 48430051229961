<template>
    <th
        v-if="isHeader"
        :class="rowField.titleClass"
        @click="onColumnHeaderClickedBackend(rowField, $event)">
        <div v-html="title"></div>
    </th>
    <td v-else>
        <Component
            :is="role === 'admin' ? 'CustomOfferOutput' : 'OfferOutput'"
            :offer="offer">
        </Component>
    </td>
</template>

<script>
  import OfferOutput from "@/components/Common/Output/OfferOutput.vue";
  import { StatisticTableView } from "@core/mixins";
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
  import { mapState } from "vuex";
  export default {
    name: "StatisticTableViewOfferOutput",
    mixins: [StatisticTableView],
    components: { CustomOfferOutput, OfferOutput },
    computed: {
      ...mapState({
        role: ({ auth }) => auth.role
      }),

      offer () {
        const {
          rowData,
          rowField: { label }
        } = this;

        return this.formatter(this.getMethodByPath(rowData, label));
      }
    }
  };
</script>

<style lang="scss" scoped>
    th {
        padding: 0.8em 0.5em;

        & > div {
            display: flex;
            white-space: nowrap;

            ::v-deep {
                i {
                    margin-left: 0.5em;
                }
            }
        }
    }

    .v-popover {
        vertical-align: middle;
    }

    .image {
        &.is-38x38 {
            $size: 38px;

            width: $size;
            height: $size;
            max-width: $size;
            min-width: $size;
        }
    }
</style>
