<template>
    <ModalBox
        :is-active="isSubAccountsModalActive"
        :is-loading="isLoading.subAccount"
        @after-leave="SET_EMPTY"
        @close="UPDATE_MODAL_ACTIVE(false)">
        <template #header>
            <h5 class="is-5 title">
                {{ $t('admin.users.webmasters.modal.subAccounts.header.title') }}
            </h5>
        </template>
        <div v-if="subAccounts.length">
            <b-table :data="subAccounts">
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.subAccounts.body.table.id`)">
                    {{ formatEmptyString(row.intId) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.subAccounts.body.table.login`)">
                    {{ formatEmptyString(row.login) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.subAccounts.body.table.email`)">
                    {{ formatEmptyString(row.contact.email) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.subAccounts.body.table.telegram`)">
                    {{ formatEmptyString(row.contact.telegram) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.subAccounts.body.table.lastTransactionDate`)">
                    {{ formatEmptyString(moment(row.lastTransactionDate)) }}
                </b-table-column>
                <b-table-column v-slot="{ row }">
                    <Permissions
                        :permissions="[
                            'WEBMASTERS.BLOCK.ALL','WEBMASTERS.BLOCK.OWN',
                            'WEBMASTERS.UNBLOCK.ALL', 'WEBMASTERS.UNBLOCK.OWN'
                        ]"
                        :validators="webmasterOwnValidators(row.isBindedToCurrentAdmin)">
                        <LTooltip
                            :label="$t(`admin.users.webmasters.table.buttons.${ row.activity.isBlocked ? 'unblock' : 'block' }`)"
                            position="is-left"
                            type="is-dark">
                            <b-button
                                :loading="isLoading.button && webmasterId === row.id"
                                rounded
                                size="is-small"
                                :icon-left="blockButtonIcon(row.activity.isBlocked)"
                                :type="blockButtonType(row.activity.isBlocked)"
                                @click="onToggleBlock(row, true)">
                            </b-button>
                        </LTooltip>
                    </Permissions>
                </b-table-column>

                <template #empty>
                    <section class="section pb-0">
                        <div class="content has-text-grey has-text-centered">
                            <p>{{ $t(`admin.users.webmasters.modal.subAccounts.body.table.notFound`) }}</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import Permissions from "@/components/Common/Permissions.vue";
  import { mapActions, mapState } from "vuex";
  import {
    SET_EMPTY,
    BLOCK_WEBMASTER,
    GET_SUB_ACCOUNTS,
    UNBLOCK_WEBMASTER,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import { formatEmptyString, moment } from "@core/filters";

  export default {
    name: "WebmastersTableSubAccountsModal",
    mixins: [TableOptionsMixin],
    components: { Permissions, ModalBox },

    data () {
      return {
        webmasterId: null
      };
    },

    computed: {
      ...mapState("admin/users/webmasters/subAccountsModal", {
        isSubAccountsModalActive: state => state.isSubAccountsModalActive,
        subAccounts: state => state.subAccounts?.items ?? []
      }),

      isLoading () {
        return {
          subAccount: this.$wait(`admin/users/webmasters/subAccountsModal/${ GET_SUB_ACCOUNTS }`),
          button: this.$wait(`admin/users/webmasters/subAccountsModal/${ UNBLOCK_WEBMASTER }`) ||
            this.$wait(`admin/users/webmasters/subAccountsModal/${ BLOCK_WEBMASTER }`)
        };
      }
    },

    methods: {
      formatEmptyString,
      moment,

      ...mapActions("admin/users/webmasters/subAccountsModal", {
        UPDATE_MODAL_ACTIVE,
        UNBLOCK_WEBMASTER,
        BLOCK_WEBMASTER,
        SET_EMPTY
      })
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .animation-content {
      width: 100%;
      max-width: 800px !important;

      .modal-card {
        width: 100%;
        max-width: none;
        min-height: 250px;
        border: none;
      }
    }

    th .th-wrap {
      font-size: 14px;
    }
  }
</style>