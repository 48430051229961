var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBox',{attrs:{"is-active":_vm.isModalActive},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(_vm.$t("admin.users.webmasters.modal.referrals.header.title"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"is-flex is-align-items-center mb-4"},[_c('b',{staticClass:"is-6 mr-2 nowrap"},[_vm._v(" "+_vm._s(_vm.$t("admin.users.webmasters.modal.referrals.body.refer.title"))+" ")]),(_vm.referrer && !_vm.isLoading.refer)?_c('UserOutput',{attrs:{"user":_vm.referrer}}):_vm._e(),(!_vm.referrer && !_vm.isLoading.refer)?_c('span',{staticClass:"empty"},[_vm._v(" "+_vm._s(_vm.$t("admin.users.webmasters.modal.referrals.body.refer.empty"))+" ")]):_vm._e(),(_vm.isLoading.refer)?_c('b-skeleton',{attrs:{"width":"60px","height":"25px","animated":""}}):_vm._e()],1),_c('b-table',{attrs:{"data":_vm.referrals || [],"loading":_vm.isLoading.referrals},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('h2',{staticClass:"is-size-5 p-3 is-bold has-text-weight-medium has-text-grey"},[_vm._v(" "+_vm._s(_vm.$t("admin.users.webmasters.modal.referrals.body.value.empty"))+" ")])])]},proxy:true}])},[_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.users.webmasters.modal.referrals.body.labels.id")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.intId))+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","label":_vm.$t("admin.users.webmasters.modal.referrals.body.labels.referral")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatEmptyString(row.login))+" ")]}}])}),(_vm.hasToggleReferral)?_c('b-table-column',{attrs:{"width":"25%","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var canBeReferral = ref_row.canBeReferral;
var id = ref_row.id;
return [_c('LTooltip',{attrs:{"label":_vm.$t(("admin.users.webmasters.modal.referrals.body.details." + (!canBeReferral))),"position":"is-left","type":("is-" + (!canBeReferral ? 'success' : 'danger'))}},[_c('b-button',{attrs:{"icon-left":((!canBeReferral ? 'check' : 'times') + "-circle"),"icon-pack":"fas","loading":_vm.isLoading.button,"rounded":"","size":"is-small","type":("is-" + (!canBeReferral ? 'success' : 'danger') + " is-light")},on:{"click":function($event){return _vm.actionReferral({ id: id, canBeReferral: canBeReferral })}}})],1)]}}],null,false,143879365)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }