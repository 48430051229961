<template>
    <th
        v-if="isHeader"
        @click="onColumnHeaderClickedBackend(rowField, $event)">
        <!--        rowData: {{ rowData }}-->
        <!--        rowIndex: {{ rowIndex }}-->
        <!--        rowField: {{ rowField }}-->
        <!--        isHeader: {{ isHeader }}-->
        <!--        title: {{ title }}-->
        <div v-html="title"></div>
    </th>
    <td v-else>
        {{ formatEmptyString(formatter(getMethodByPath(rowData, rowField.label))) }}
    </td>
</template>

<script>
  import { StatisticTableView } from "@core/mixins";

  export default {
    name: "StatisticTableViewNumber",
    mixins: [StatisticTableView]
  };
</script>

<style lang="scss" scoped>
    th {
        padding: 0.8em 0.5em;

        & > div {
            display: flex;
            white-space: nowrap;
            justify-content: flex-end;

            ::v-deep {
                i {
                    margin-left: 0.5em;
                }
            }
        }
    }

    .v-popover {
        vertical-align: middle;
    }

    .image {
        &.is-38x38 {
            $size: 38px;

            width: $size;
            height: $size;
            max-width: $size;
            min-width: $size;
        }
    }
</style>
