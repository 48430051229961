
























































































































































































































































































































































































import WebmastersTableReferralModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableReferralModal";
import WebmastersTableOptions from "@/components/Admin/Users/WebmastersTab/WebmastersTableOptions";
import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
import DropdownComment from "@/components/Common/Controls/DropdownComment.vue";
import InlineLoader from "@/components/Common/Controls/InlineLoader.vue";
import CompactList from "@/components/Common/Lists/CompactList.vue";
import UserOutput from "@/components/Common/Output/UserOutput.vue";
import Countries from "@/components/Common/Lists/Countries.vue";
import ShowMore from "@/components/Common/ShowMore";
import LTooltip from "@/components/Common/Tooltip/LTooltip.vue";
import ColoredStatus from "@/components/Common/ColoredStatus";
import FinancesCostsCreatePaymentModal from "@/components/Admin/Finances/FinancesCosts/FinancesCostsCreatePaymentModal";
import WebmastersTableDetailsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal";
import LTable from "@/components/Common/LTable";
import PreferredCurrencyCheckbox from "@/components/Common/Controls/PreferredCurrencyCheckbox.vue";
import WebmastersTableSubAccountsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableSubAccountsModal";
import { formatEmptyString, momentFrom } from "@core/filters";
import { fixedCurrency, momentWithEmpty } from "@core/flowMethods";
import { computed } from "@vue/composition-api";
import { i18n } from "@core/plugins";
import ContactOutput from "@/components/Common/Output/ContactOutput.vue";
import UserContacts from "@/components/Common/Output/UserContacts.vue";
import SubWebmasterBlockAccountModal from "@/components/Admin/Users/WebmastersTab/SubWebmasterBlockAccountModal.vue";
import { defineComponent } from "@vue/composition-api";
import { EDIT_WEBMASTER_ADMIN_COMMENT, UPDATE_WEBMASTER_ID, GET_WEBMASTERS, SET_REFERRALS, SET_REFERRER } from "@core/store/action-constants";
import { UPDATE_WEBMASTER_EDITABLE_COMMENT, UPDATE_WEBMASTERS_SORTINGS } from "@core/store/mutation-constants";
import { mapActions, mapMutations, mapState } from "vuex";
import { actionWithToast } from "@/helpers/actionWithToast";
import { User } from "@core/store/types/common";
import { hasPermissions } from "@core/mixins/permissions";
const __sfc_main = defineComponent({
  name: "WebmastersTable",
  data() {
    return {
      isVisiblePreferredCurrency: true,
      detailsModal: false,
      detailsModalData: null,
      defaultSort: []
    };
  },
  computed: {
    ...mapState("admin/users/webmasters", ["webmasters", "sort"]),
    // @ts-ignore
    ...mapState({
      isShowCommissions: ({
        auth
      }) => hasPermissions(["STATISTICS.SHOW_COMMISSION"], auth.userPermissions)
    }),
    preferredCurrency() {
      return this.$store.state.auth.preferredCurrency;
    },
    residencePlaceField(): string {
      return this.sort?.indexOf("Country") !== -1 ? "residenceCountryName" : "residenceCityName";
    },
    isLoading(): {
      [key: string]: boolean;
    } {
      const namespace = "admin/users/webmasters";
      return {
        // @ts-ignore
        getWebmasters: this.$wait(`${namespace}/${GET_WEBMASTERS}`),
        // @ts-ignore
        updateComment: this.$wait(`${namespace}/${EDIT_WEBMASTER_ADMIN_COMMENT}`)
      };
    }
  },
  methods: {
    ...mapActions("admin/users/webmasters", {
      GET_WEBMASTERS_WITH_BALANCE: "GET_WEBMASTERS_WITH_BALANCE",
      EDIT_WEBMASTER_ADMIN_COMMENT: "EDIT_WEBMASTER_ADMIN_COMMENT"
    }),
    ...mapActions("admin/users/webmasters/referralsModal", {
      UPDATE_REFERRALS_OPTIONS: "UPDATE_MODAL_OPTIONS",
      UPDATE_REFERRALS_MODAL_ACTIVE: "UPDATE_MODAL_ACTIVE"
    }),
    ...mapActions("admin/users/webmasters/subAccountsModal", {
      GET_SUB_ACCOUNTS: "GET_SUB_ACCOUNTS",
      UPDATE_SUB_ACCOUNTS_MODAL_ACTIVE: "UPDATE_MODAL_ACTIVE"
    }),
    ...mapActions("admin/users/webmasters/detail", {
      UPDATE_WEBMASTER_ID
    }),
    ...mapMutations("admin/users/webmasters", {
      SET_REFERRALS: `referralsModal/${SET_REFERRALS}`,
      SET_REFERRER: `referralsModal/${SET_REFERRER}`,
      UPDATE_WEBMASTER_EDITABLE_COMMENT,
      UPDATE_WEBMASTERS_SORTINGS
    }),
    updateSortings(sort: string, order: string) {
      if (sort && order) {
        // @ts-ignore
        this.defaultSort = [sort, order];
        // @ts-ignore
        this.$refs.table.$refs.lTable.resetMultiSorting();
        this.UPDATE_WEBMASTERS_SORTINGS({
          sort,
          order
        });
        this.GET_WEBMASTERS_WITH_BALANCE();
      }
    },
    coloredStatus(status: boolean) {
      return status ? "is-success" : "is-danger";
    },
    countryToArray(country: string) {
      return country ? [country] : [];
    },
    webmastersTableDetailsModalOpen(value: any) {
      // @ts-ignore
      this.$ym?.reachGoal("USER_DETAILS");
      this.detailsModal = true;
      this.detailsModalData = value;
      this.UPDATE_WEBMASTER_ID(value.id);
    },
    subAccountsModalOpen({
      id
    }: User) {
      this.GET_SUB_ACCOUNTS(id);
      this.UPDATE_SUB_ACCOUNTS_MODAL_ACTIVE(true);
    },
    referralsModalOpen({
      id: webmasterId,
      referrer,
      referrals
    }: any) {
      this.UPDATE_REFERRALS_OPTIONS({
        webmasterId
      });
      this.SET_REFERRER(referrer);
      this.SET_REFERRALS(referrals);
      this.UPDATE_REFERRALS_MODAL_ACTIVE(true);
    },
    async changeComment(comment: string, userId: string) {
      this.UPDATE_WEBMASTER_EDITABLE_COMMENT({
        userId,
        comment
      });
      await actionWithToast(await this.EDIT_WEBMASTER_ADMIN_COMMENT(), "admin.efficiency.plans.messages");
      // @ts-ignore
      this.$refs[`dropdown-${userId}`].toggle();
    },
    getSubWebmasterLabel(agent: User) {
      return `${this.$t("admin.users.webmasters.filters.values.isSubWebmaster")} ${agent.login} [${agent.intId}]`;
    },
    isAgent({
      subType
    }: {
      subType: string;
    }) {
      return subType === "AGENT";
    },
    isSubWebmaster(subType: string) {
      return subType === "SUB_WEBMASTER";
    }
  },
  watch: {
    detailsModal(value) {
      if (value === false) this.detailsModalData = null;
    }
  }
});
__sfc_main.setup = (__props, __ctx) => {
  const residencePlaceFields = computed(() => [{
    value: "residenceCountryName",
    label: i18n.t("admin.users.webmasters.table.labels.dropdownSorting.residenceCountry")
  }, {
    value: "residenceCityName",
    label: i18n.t("admin.users.webmasters.table.labels.dropdownSorting.residenceCity")
  }]);
  return {
    formatEmptyString,
    momentFrom,
    fixedCurrency,
    momentWithEmpty,
    residencePlaceFields
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  ColoredStatus,
  WebmasterOutput,
  LTooltip,
  PreferredCurrencyCheckbox,
  InlineLoader,
  Countries,
  ContactOutput,
  UserContacts,
  CompactList,
  AdminOutput,
  UserOutput,
  ShowMore,
  DropdownComment,
  WebmastersTableOptions,
  FinancesCostsCreatePaymentModal,
  WebmastersTableDetailsModal,
  WebmastersTableSubAccountsModal,
  WebmastersTableReferralModal,
  SubWebmasterBlockAccountModal
}, __sfc_main.components);
export default __sfc_main;
