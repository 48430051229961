<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :value="value"
        :get-data-vuex="getOffers"
        :placeholder="placeholder"
        :multiple="multiple"
        :seed="customSeed"
        class="white"
        @[event]="$emit(event, $event)"
        @option:selecting="$emit('option:selecting', $event)">
        <template #text="option">
            <OfferOutput :offer="option.option"></OfferOutput>
        </template>
        <template #selected-option="option">
            <OfferOutput :offer="option.option"></OfferOutput>
        </template>
    </Component>
</template>

<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import OfferOutput from "@/components/Common/Output/OfferOutput.vue";

  export default {
    name: "OffersSelect",
    mixins: [UsersSelect],

    components: { OfferOutput },

    props: {
      routeParam: {
        type: String,
        default: "offerId"
      },
      namespaceModule: {
        type: String,
        default: "offersFilters"
      },
      vertical: {
        type: [Boolean, String],
        default: false
      },
      seed: {
        type: null,
        default: null
      }
    },

    computed: {
      currentVertical () {
        return this.$store.state.verticals.currentVertical;
      },

      customSeed () {
        return this.seed ? this.seed + (this.vertical ? this.currentVertical : "") : this.vertical ? this.currentVertical : "";
      }
    },

    methods: {
      async getOffers (label = "", isLoading = false) {
        const { filterOptions, vertical, value, routeParam, namespaceModule, currentVertical } = this;
        await this.getOfferFilters(
          namespaceModule, this.list, this.$route.query[routeParam] ?? value,
          routeParam, label, isLoading, { ...filterOptions, vertical: vertical ? typeof vertical === "boolean" ? currentVertical : vertical : null }
        );
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>
