<template>
    <SectionWrapper
        is-async
        :action-button-options="actionButtonOptions"
        @action="UPDATE_CHECK_MODAL_ACTIVE(true)">
        <template #sticky>
            <WebmastersFilters></WebmastersFilters>
        </template>

        <WebmastersTable></WebmastersTable>
        <WebmastersFiltersCheckModal></WebmastersFiltersCheckModal>
    </SectionWrapper>
</template>

<script>
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import WebmastersFilters from "@/components/Admin/Users/WebmastersTab/WebmastersFilters.vue";
  import WebmastersTable from "@/components/Admin/Users/WebmastersTab/WebmastersTable.vue";
  import { UPDATE_MODAL_ACTIVE } from "@core/store/action-constants";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";
  import WebmastersFiltersCheckModal from "@/components/Admin/Users/WebmastersTab/WebmastersFiltersCheckModal.vue";

  export default {
    name: "UsersWebmasters",

    components: {
      SectionWrapper,
      WebmastersFilters,
      WebmastersTable,
      WebmastersFiltersCheckModal
    },

    computed: {
      actionButtonOptions () {
        return {
          icon: "search",
          iconSize: "is-small",
          label: this.$t("admin.users.webmasters.filters.labels.findWebmaster")
        };
      }
    },

    methods: {
      ...mapActions("admin/users/webmasters", {
        SET_EMPTY,
        UPDATE_CHECK_MODAL_ACTIVE: `checkModal/${ UPDATE_MODAL_ACTIVE }`
      })
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped></style>
