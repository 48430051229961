<template>
    <b-dropdown
        v-if="$slots.default"
        aria-role="list"
        position="is-bottom-left"
        @active-change="state => (this.isActive = state)">
        <LTooltip
            slot="trigger"
            :always="isActive"
            :label="$t('common.components.dropdownOptions.tooltip')"
            position="is-left"
            type="is-white">
            <b-button
                :disabled="disabled"
                :rounded="rounded"
                :size="size"
                type="is-light is-light">
                <b-icon icon="ellipsis-h"></b-icon>
            </b-button>
        </LTooltip>
        <b-dropdown-item
            :key="key"
            v-for="(option, key) in $slots.default"
            aria-role="listitem">
            <VNode
                :vnodes="option"></VNode>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
  import VNode from "@/components/Common/VNode";
  
  export default {
    name: "DropdownOptions",
    components: { VNode },
    props: {
      rounded: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "is-small"
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isActive: false
      };
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .dropdown-content {
            .dropdown-item {
                padding: 0 !important;

                .media {
                    align-items: center;
                }

                & > .button {
                    width: 100%;
                    color: black;
                    display: flex;
                    border-radius: 0;
                    justify-content: left;

                    &:not(:hover) {
                        background-color: white;
                    }

                    &:hover {
                        font-weight: bolder;

                        .button {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
</style>
