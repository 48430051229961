<template>
    <ModalBox
        :is-active="isSubAccountsBlockAccountModal"
        @close="close">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.users.webmasters.confirm.block`) }}
            </h5>
        </template>

        <p
            v-if="balance.length"
            class="is-size-6">
            {{ $t("admin.users.webmasters.confirm.availableBalance") }}:
            <br>
            <span
                :key="idx"
                v-for="({ balance }, idx) in balance">
                <template>
                    {{ fixedCurrency(balance) }} <br>
                </template>
            </span>
            <br>
            {{ $t("admin.users.webmasters.confirm.subWebmasterDescription") }}
        </p>

        <template #footer>
            <div class="columns">
                <div class="column mb-2">
                    <b-button
                        :loading="isLoading"
                        :expanded="!balance.length"
                        type="is-info is-light"
                        @click="onBlockSubWebmaster(false)">
                        {{ $t("admin.users.webmasters.confirm.subWebmasterBlock") }}
                    </b-button>
                </div>
                <div
                    v-if="balance.length"
                    class="column">
                    <b-button
                        :loading="isLoading"
                        type="is-info"
                        @click="onBlockSubWebmaster(true)">
                        {{ $t("admin.users.webmasters.confirm.subWebmasterBalanceTransfer") }}
                    </b-button>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { useBadges } from "@/stores/common/badgesStore";
  import { BadgesEnum } from "@core/store/types/common/enums/badgesEnum";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { BLOCK_WEBMASTER, UPDATE_SUB_ACCOUNTS_BLOCK } from "@core/store/action-constants";
  import { mapActions, mapMutations, mapState } from "vuex";
  import { fixedCurrency } from "@core/flowMethods";

  export default {
    name: "SubWebmasterBlockAccountModal",
    components: { ModalBox },

    setup () {
      return {
        badgesStore: useBadges([BadgesEnum.WEBMASTERS])
      };
    },

    computed: {
      ...mapState("admin/users/webmasters/subAccountsModal", {
        isSubAccountsBlockAccountModal: state => state.subWebmasterBlock.isSubAccountsBlockAccountModal,
        subWebmasterBlockData: state => state.subWebmasterBlock.subWebmasterBlockData,
        isSubAccountsModalActive: state => state.isSubAccountsModalActive
      }),

      isLoading () {
        return this.$wait(`admin/users/webmasters/${ BLOCK_WEBMASTER }`) || this.$wait(`admin/users/webmasters/subAccountsModal/${ BLOCK_WEBMASTER }`);
      },

      balance () {
        return this.subWebmasterBlockData?.balance?.filter(el => el.balance.value > 0) || [];
      }
    },

    methods: {
      fixedCurrency,
      ...mapActions("admin/users/webmasters", { BLOCK_WEBMASTER }),
      ...mapMutations("admin/users/webmasters/subAccountsModal", { UPDATE_SUB_ACCOUNTS_BLOCK }),
      ...mapActions("admin/users/webmasters/subAccountsModal", { BLOCK_SUB_WEBMASTER: BLOCK_WEBMASTER }),

      close () {
        this.UPDATE_SUB_ACCOUNTS_BLOCK({ isSubAccountsBlockAccountModal: false, subWebmasterBlockData: null });
      },

      async onBlockSubWebmaster (transferBalance = false) {
        const id = this.subWebmasterBlockData.id;
        await actionWithToast(
          this[this.isSubAccountsModalActive ? "BLOCK_SUB_WEBMASTER" : BLOCK_WEBMASTER]({ id, transferBalance }),
          `admin.users.webmasters.messages.block${ transferBalance ? "SubWebmaster" : "" }`,
          {},
          { userLogin: this.subWebmasterBlockData.userLogin }
        );
        this.close();
        await this.badgesStore.GET_BADGES();
      }
    }
  };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .animation-content {
            width: 100%;
            max-width: 550px !important;

            .modal-card {
                width: 100%;
                max-width: none;

                &-head {
                    padding-bottom: 15px;
                }

                &-foot {
                    padding-top: 15px;
                }
            }
        }
    }
</style>
