<template>
    <th
        v-if="isHeader"
        :class="{ 'is-shadow': calculateLastColumnsIndex === rowField.$_index }">
        <!--        rowData: {{ rowData }}-->
        <!--        rowIndex: {{ rowIndex }}-->
        <!--        rowField: {{ rowField }}-->
        <!--        isHeader: {{ isHeader }}-->
        <!--        title: {{ title }}-->
        <div
            class="has-text-centered is-align-items-center"
            @click="onColumnHeaderClicked(rowField, $event)">
            <LTooltip
                :label="rowField.title"
                position="is-bottom"
                type="is-white">
                <!--suppress HtmlUnknownTarget -->
                <img
                    :alt="rowField.title"
                    :src="imageSrc(rowField.icon)"
                    class="image is-38x38">
            </LTooltip>
            <span
                ref="title"
                v-html="renderTitle"></span>
        </div>
    </th>
    <td
        v-else
        :class="{ 'is-shadow': calculateLastColumnsIndex === rowField.$_index }">
        {{ formatEmptyString(formatter(getMethodByPath(rowData, rowField.label))) }}
    </td>
</template>

<script>
  import { StatisticTableView } from "@core/mixins";
  import _last from "lodash/last";

  export default {
    name: "StatisticTableViewIconsItem",
    mixins: [StatisticTableView],

    computed: {
      renderTitle () {
        const sandbox = document.createElement("div");
        sandbox.innerHTML = this.title;
        for (const node of sandbox.childNodes) {
          if (node.nodeType === 3) {
            node.remove();
          }
        }
        return sandbox.innerHTML;
      },
      icons () {
        return {
          approve: "approve.svg",
          approveClean: "approveClean.svg",
          leads: "leads.svg",
          leadConfirmed: "leadConfirmed.svg",
          leadProcessed: "leadProcessed.svg",
          leadCancelled: "leadCancelled.svg",
          leadTrash: "leadTrash.svg",
          filteredLeads: "filteredLeads.svg",
          accruedAdmin: "accruedAdmin.svg",
          moneyConfirmed: "moneyConfirmed.svg",
          commission: "commissionDomonetization.svg",
          commissionLeads: "commissionLeads.svg",
          accrued: "accruedDomonetization.svg",
          redemption: "redemption.svg",
          leadIntegrated: "leadIntegrated.svg",
          leadSpamByAdvertiser: "leadSpamByAdvertiser.svg",
          leadsHold: "leadsHold.svg",
          averageMoneyCommission: "averageMoneyCommission.svg",
          accruedDomonetization: "accruedDomonetization.svg",
          commissionDomonetization: "commissionDomonetization.svg",
          incomeDomonetization: "accruedDomonetization.svg",
          approveByCPL: "approveByCPL.svg",
          commissionAgent: "commissionAgent.svg",
          moneyWebmasterAccrued: "moneyWebmasterAccrued.svg"
        };
      },

      calculateLastColumnsIndex () {
        const { headerName } = this.rowField;
        return _last(this.vuetable.tableFields.filter((item) =>
          item.headerName === headerName && item.visible)).$_index;
      }
    },

    methods: {
      imageSrc (label) {
        return require(`@/assets/Table/${ this.icons[label] }`);
      }
    }
  };
</script>

<style lang="scss" scoped>
    th {
        padding: 0.8em 0.5em;

        & > div {
            display: flex;
            white-space: nowrap;
            justify-content: center;

            ::v-deep {
                i {
                    margin-left: 0.5em;
                }
            }
        }
    }

    .v-popover {
        vertical-align: middle;
    }

    .image {
        &.is-38x38 {
            $size: 38px;

            width: $size;
            height: $size;
            max-width: $size;
            min-width: $size;
        }
    }
</style>
