<template>
    <ModalBox
        :is-active="isCheckModalActive"
        @close="onClose">
        <template #header>
            <h5 class="title is-5">
                {{ $t(`admin.users.webmasters.modal.find.header.title`) }}
            </h5>
        </template>
        <div class="columns">
            <div class="column is-8">
                <b-field :label="$t('admin.users.webmasters.modal.find.body.input')">
                    <LInput
                        v-model="webmaster"
                        :placeholder="$t('admin.users.webmasters.modal.find.body.input')">
                    </LInput>
                </b-field>
            </div>
            <div class="column is-4">
                <!-- eslint-disable-next-line no-irregular-whitespace -->
                <b-field label=" ">
                    <b-button
                        type="is-info is-light"
                        class="is-fullwidth"
                        :loading="isLoading"
                        @click="CHECK_WEBMASTER(webmaster)">
                        {{ $t("admin.users.webmasters.modal.find.buttons.find") }}
                    </b-button>
                </b-field>
            </div>
        </div>
        <div v-if="webmasters">
            <b-table
                :data="webmasters">
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.id`)">
                    {{ formatEmptyString(row.intId) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.login`)">
                    {{ formatEmptyString(row.login) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.email`)">
                    {{ formatEmptyString(row.contact.email) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.telegram`)">
                    {{ formatEmptyString(row.contact.telegram) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.regDate`)">
                    {{ formatEmptyString(moment(row.activity.registeredAt)) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.datePayout`)">
                    {{ formatEmptyString(moment(row.lastTransactionDate)) }}
                </b-table-column>
                <b-table-column
                    v-slot="{ row }"
                    :label="$t(`admin.users.webmasters.modal.find.body.table.manager`)">
                    <CompactList :items="row.admins">
                        <template #listItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                        <template #tooltipItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                        <template #singleItem="{ item }">
                            <UserOutput :user="item"></UserOutput>
                        </template>
                    </CompactList>
                </b-table-column>

                <template #empty>
                    <section class="section pb-0">
                        <div class="content has-text-grey has-text-centered">
                            <p>{{ $t(`admin.users.webmasters.modal.find.body.table.notFound`) }}</p>
                        </div>
                    </section>
                </template>
            </b-table>
        </div>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import LInput from "@/components/Common/Select/LInput.vue";
  import CompactList from "@/components/Common/Lists/CompactList.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";

  import { mapActions, mapState } from "vuex";
  import {
    CHECK_WEBMASTER,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import { formatEmptyString, moment } from "@core/filters";


  export default {
    name: "WebmastersFiltersCheckModal",

    components: {
      CompactList,
      UserOutput,
      ModalBox,
      LInput
    },

    data () {
      return {
        webmaster: null
      };
    },

    computed: {
      ...mapState("admin/users/webmasters/checkModal", {
        webmasters: state => state.options.webmasters,
        isCheckModalActive: state => state.isCheckModalActive
      }),

      isLoading () {
        return this.$wait(`admin/users/webmasters/checkModal/${ CHECK_WEBMASTER }`);
      }
    },

    methods: {
      formatEmptyString,
      moment,

      ...mapActions("admin/users/webmasters/checkModal", [
        CHECK_WEBMASTER,
        UPDATE_MODAL_ACTIVE
      ]),

      onClose () {
        this.webmaster = null;
        this.UPDATE_MODAL_ACTIVE(false);
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .animation-content {
      width: 100%;
      max-width: 800px !important;

      .modal-card {
        width: 100%;
        max-width: none;
        border: none;
      }
    }

    th .th-wrap {
      font-size: 14px;
    }
  }
</style>
