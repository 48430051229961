// @vue/components
import { BadgesEnum } from "@core/store/types/common/enums/badgesEnum";
import { UPDATE_SUB_ACCOUNTS_BLOCK } from "@core/store/action-constants";
import { actionWithToast } from "@/helpers/actionWithToast";
import { useBadges } from "@/stores/common/badgesStore";
import { mapMutations } from "vuex";

export default {
  setup () {
    return {
      badgesStore: useBadges([BadgesEnum.WEBMASTERS])
    };
  },

  methods: {
    ...mapMutations("admin/users/webmasters/subAccountsModal", { UPDATE_SUB_ACCOUNTS_BLOCK }),

    onToggleBlock ({ id, activity: { isBlocked }, login: userLogin, balance }, isSubWebmaster = false) {
      if (!isBlocked && isSubWebmaster) {
        this.UPDATE_SUB_ACCOUNTS_BLOCK({ isSubAccountsBlockAccountModal: true, subWebmasterBlockData: { id, userLogin, balance } });
      } else {
        const dataSet = {
          "true": { action: this.UNBLOCK_WEBMASTER, text: "unblock" },
          "false": { action: this.BLOCK_WEBMASTER, text: "block" }
        };

        this.$buefy.dialog.confirm({
          title: this.$t(`admin.users.webmasters.confirm.${ dataSet[isBlocked].text }`),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            try {
              this.webmasterId = id;
              await actionWithToast(
                  dataSet[isBlocked].action({ id }),
                  `admin.users.webmasters.messages.${ dataSet[isBlocked].text }`,
                  {},
                  { userLogin }
              );
              await this.badgesStore.GET_BADGES();
            } finally {
              this.webmasterId = null;
            }
          }
        });
      }
    },

    webmasterOwnValidators (isBindedToCurrentAdmin) {
      const func = () => isBindedToCurrentAdmin;

      return {
        "WEBMASTERS.LOGIN.OWN": func,
        "WEBMASTERS.APPROVE.OWN": func,
        "WEBMASTERS.BLOCK.OWN": func,
        "WEBMASTERS.UNBLOCK.OWN": func
      };
    },

    bindButtonType (isBoundToCurrentAdmin) {
      return isBoundToCurrentAdmin ? "is-danger is-light" : "is-success is-light";
    },
    
    bindButtonIcon (isBoundToCurrentAdmin) {
      return isBoundToCurrentAdmin ? "user-minus" : "user-plus";
    },
    
    blockButtonType (blocked) {
      return blocked ? "is-warning is-light" : "is-danger is-light";
    },
    
    blockButtonIcon (blocked) {
      return blocked ? "unlock" : "lock";
    },

    agentAccessButtonType (isAgent) {
      return isAgent ? "is-warning is-light" : "is-success is-light";
    },
    
    apiButtonType (isEnableApi) {
      return isEnableApi ? "is-danger is-light" : "is-success is-light";
    }
  }
};
