<template>
    <div class="columns is-multiline">
        <div class="column is-2">
            <CountriesSelect
                v-model="country"
                multiple
                :placeholder="$t(`webmaster.offers.filters.labels.country`)">
            </CountriesSelect>
        </div>

        <AccessControlFilter
            v-model="offer"
            type="offer"
            label=""
            :filter-options="{ webmaster: webmasterId }"
            wrapper-class="column is-2"
            :placeholder="$t(`common.entity.filters.offer`)">
        </AccessControlFilter>
    </div>
</template>

<script>
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import { UPDATE_WEBMASTER_RATES_FILTERS } from "@core/store/action-constants";
  import { mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  
  export default {
    name: "IndividualRateFilters",
    components: {
      AccessControlFilter,
      CountriesSelect
    },
    computed: {
      ...mapState("admin/users/webmasters/detail", {
        webmasterId: state => state.webmasterId
      }),

      ...mapFields("admin/users/webmasters/detail/rates", {
        fields: ["offer", "country"],
        base: "filters",
        action: UPDATE_WEBMASTER_RATES_FILTERS
      })
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
