<template>
    <div class="columns is-multiline">
        <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
            <div class="column is-2">
                <b-field>
                    <Select
                        v-model="filters.advertiserId"
                        class="white"
                        :get-data="getAdvertisers"
                        field="login"
                        :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.advertiser`)">
                        <template #text="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                        <template #selected-option="{ option }">
                            <UserOutput :user="option"></UserOutput>
                        </template>
                    </Select>
                </b-field>
            </div>
        </Permissions>
        <div class="column is-2">
            <b-field>
                <OffersSelect
                    v-model="filters.offerId"
                    :placeholder="$t(`admin.users.webmasters.modal.details.header.filters.labels.offer`)">
                </OffersSelect>
            </b-field>
        </div>
        <div class="column is-2">
            <b-field>
                <Select
                    v-model="filters.groups[0]"
                    :get-data="getGroups"
                    expanded
                    placeholder="-"
                    field="label"
                    prop="value"
                    :searchable="false">
                </Select>
            </b-field>
        </div>
        <div class="column is-narrow">
            <b-field>
                <CurrencyCheckbox v-model="filters.currency"></CurrencyCheckbox>
            </b-field>
        </div>
        <div class="column is-narrow">
            <CustomDatepicker
                v-model="filters.datepicker"
                class="is-pulled-right"
                label=""></CustomDatepicker>
        </div>
    </div>
</template>

<script>
  import CurrencyCheckbox from "@/components/Common/Controls/CurrencyCheckbox.vue";
  import OffersSelect from "@/components/Common/Select/OffersSelect.vue";
  import Permissions from "@/components/Common/Permissions.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import { Filters, UsersFilters } from "@core/mixins";
  import { UPDATE_ADVERTISERS_DICTIONARY } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";

  export default {
    name: "DetailsFilters",
    mixins: [Filters, UsersFilters],
    components: {
      Permissions,
      CurrencyCheckbox,
      CustomDatepicker,
      Select,
      OffersSelect,
      UserOutput
    },
    methods: {
      ...mapActions("admin", [
        UPDATE_ADVERTISERS_DICTIONARY
      ]),

      getAdvertisers (offset = 0, label = "") {
        return this.getUsersByActionName(this.UPDATE_ADVERTISERS_DICTIONARY, label, offset);
      },

      getGroups () {
        const items = ["day", "month"].map(group => ({
          label: this.$t(`common.groupsList.${ group }`),
          value: group
        }));
        return { items, count: items.length };
      },

      clearFilters () {
        this.emitClear();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        flex-basis: 240px;
    }
</style>
