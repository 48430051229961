<template>
    <component
        :is="tag"
        :class="type"
        class="button"
        @click="onClick($event)">
        <slot name="option">
            <div class="media">
                <div class="media-left">
                    <slot name="option-icon">
                        <span
                            :class="type"
                            class="button is-rounded is-small">
                            <InlineSvg
                                v-if="customIcon"
                                :src="customIcon"
                                class="custom-icon">
                            </InlineSvg>
                            <b-icon
                                v-else
                                :icon="icon"
                                :pack="pack"
                                size="is-small">
                            </b-icon>
                        </span>
                    </slot>
                </div>
                <div class="media-content">
                    <slot name="option-content">
                        <h3>
                            <slot
                                v-if="$slots.contentHeader"
                                name="option-content-header"></slot>
                            <slot v-else></slot>
                        </h3>
                        <small>
                            <slot name="option-content-subheader"></slot>
                        </small>
                    </slot>
                </div>
            </div>
        </slot>
    </component>
</template>

<script>
  import InlineSvg from "vue-inline-svg";
  // В минимальном виде в компонент нужно передать только
  // type - чтобы указать цвет
  // icon - для выбора иконки
  // pack - для выбора пака иконок

  // Для более тонкой настрокий есть следующие слоты:
  // option - родительский слот, полностью перезаписывает компонент
  // option-icon - иконка
  // option-content - перезапись слота контента
  // option-content-header - заголовок
  // option-content-subheader - подзаголовок

  // События
  // @click
  export default {
    name: "DropdownOption",
    components: {
      InlineSvg
    },

    props: {
      type: {
        type: String,
        default: "is-light-button"
      },
      tag: {
        type: String,
        default: "div"
      },
      icon: {
        type: String,
        default: null
      },
      pack: {
        type: String,
        default: null
      },
      customIcon: {
        type: String,
        default: null
      }
    },

    methods: {
      onClick (event) {
        if (!this.$attrs.disabled) {
          this.$emit("click", event);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .media {
        &-content {
            font-size: 1em;
        }
    }

    .is-light {
        .media {
            &-content {
                color: black !important;
            }
        }
    }

    .custom-icon {
        margin-left: -14.5px;
        margin-right: -14.5px;
        width: $size-5;
        height: $size-5;
    }
</style>
