<template>
    <tr>
        <td
            :colspan="colspan"
            class="has-text-centered is-shadow">
            {{ $t("common.table.total") }} <br>
            ({{ $t("common.table.average") }})
        </td>
        <td
            :key="index"
            v-for="(column, index) of columns"
            :class="{ 'is-shadow': calculateLastColumnsIndex(column.headerName) === column.$_index }"
            class="has-text-centered">
            {{
                formatEmptyString(formatter(column, getMethodByPath(total, column.label), defaultFormatter))
            }} <br>
            ({{
                formatEmptyString(formatter(column, getMethodByPath(average, column.label), defaultFormatter))
            }})
        </td>
    </tr>
</template>

<script>
  import { defaultFormatter, formatEmptyString } from "@core/filters";
  import { StatisticTableView } from "@core/mixins";
  import _last from "lodash/last";

  export default {
    name: "StatisticTableFooter",
    mixins: [StatisticTableView],
    props: {
      total: {
        type: Object,
        required: true
      },
      average: {
        type: Object,
        required: true
      },
      fields: {
        type: Array,
        required: true
      }
    },

    computed: {
      groups () {
        return this.fields.filter(field => field.visible && field.headerName.startsWith("groups"));
      },

      columns () {
        return this.fields.filter(field => field.visible && !field.headerName.startsWith("groups"));
      },

      colspan () {
        return this.groups.length;
      }
    },

    methods: {
      defaultFormatter,
      formatEmptyString,

      formatter (field, value, defaultFormatter) {
        return field.formatter ? field.formatter(value) : defaultFormatter ? defaultFormatter(value) : value;
      },

      calculateLastColumnsIndex (headerName) {
        return _last(this.fields.filter((item) =>
          item.headerName === headerName && item.visible)).$_index;
      }
    }
  };
</script>

<style scoped>

</style>
