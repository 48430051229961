// @vue/components
export const PropsExcludedSelect = {
    props: {
        includedFilters: {
            type: [Array, String, Boolean],
            default: null
        },
        excludedFilters: {
            type: [Array, String, Boolean],
            default: null
        },
        name: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
