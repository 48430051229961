<template>
    <b-dropdown
        ref="dropdownComment"
        :can-close="canClose && !isLoading"
        append-to-body
        aria-role="menu"
        position="is-bottom-left"
        trap-focus
        @active-change="onDropdownToggle($event)">
        <template #trigger>
            <b-icon
                class="ml-2"
                icon="pen"
                size="is-small"
                style="cursor: pointer"
                type="is-primary">
            </b-icon>
        </template>
        <b-dropdown-item
            :focusable="false"
            aria-role="menu-item"
            custom
            paddingless>
            <form
                class="comment-form px-4 py-2"
                @submit.prevent="changeComment()">
                <b-field>
                    <LInput
                        v-model="comment"
                        v-click-outside="onClickOutside"
                        :disabled="isLoading"
                        maxlength="500"
                        minlength="0"
                        placeholder="Comment"
                        type="textarea"
                        @mousedown.native="canClose = false"
                        @mouseup.native="canClose = true">
                    </LInput>
                </b-field>
                <b-button
                    :loading="isLoading"
                    class="is-primary"
                    native-type="submit">
                    {{ $t("common.buttons.save") }}
                </b-button>
            </form>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
  import ClickOutside from "vue-click-outside";
  
  export default {
    name: "DropdownComment",
    props: {
      value: {
        type: String,
        default: null
      },
      
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    directives: {
      ClickOutside
    },

    data () {
      return {
        comment: null,
        canClose: true
      };
    },

    methods: {
      onClickOutside () {
        if (!this.canClose) {
          this.canClose = true;
        }
      },

      onDropdownToggle (active) {
        if (active) {
          this.comment = this.value;
        }
      },

      changeComment () {
        this.$emit("update", this.comment);
      },

      toggle () {
        this.$refs.dropdownComment.toggle();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .comment-form {
        width: 300px;
    }
</style>
