<template>
    <div>
        <DropdownOptions rounded>
            <Permissions :permissions="['WEBMASTERS.EDIT.ALL', 'WEBMASTERS.EDIT.OWN']">
                <DropdownOption
                    icon="edit"
                    type="is-warning is-light"
                    @click="onEditWebmaster(row.id)">
                    {{ $t(`admin.users.webmasters.table.buttons.edit`) }}
                </DropdownOption>
            </Permissions>

            <Permissions
                :permissions="[
                    'WEBMASTERS.TOGGLE_ACCESS_TO_API.ALL',
                    'WEBMASTERS.TOGGLE_ACCESS_TO_API.OWN'
                ]">
                <DropdownOption
                    icon="code"
                    :type="apiButtonType(row.isEnableApi)"
                    @click="onToggleApi(row)">
                    {{ getPublicApiLabel(row.isEnableApi) }}
                </DropdownOption>
            </Permissions>

            <Permissions :permissions="['WEBMASTERS.BIND.ALL']">
                <DropdownOption
                    icon="user-plus"
                    type="is-success is-light"
                    @click="onToggleBind(row)">
                    {{ getBindToManagerLabel(row.admins.length !== 0) }}
                </DropdownOption>
            </Permissions>

            <DropdownOption
                v-if="(hasBindFree || hasBindAll) && row.admins.length === 0 && !isSubWebmaster(row.subType)"
                icon="user-plus"
                type="is-success is-light"
                @click="bindToSelf(row)">
                {{ $t('admin.users.common.table.buttons.bindToSelf') }}
            </DropdownOption>

            <Permissions :permissions="['OUT_TRANSACTIONS.CREATE']">
                <DropdownOption
                    icon="hand-holding-usd"
                    type="is-warning is-light"
                    @click="financesCostsCreatePaymentModalOpen(row.id)">
                    {{ $t(`admin.users.webmasters.table.buttons.createPaymentWebmaster`) }}
                </DropdownOption>
            </Permissions>

            <DropdownOption
                v-if="!row.activity.isConfirmed"
                icon="envelope"
                type="is-success is-light"
                @click="resendEmailByAdmin(row.id)">
                {{ $t(`common.mailConfirmation.resendEmail.resend`) }}
            </DropdownOption>

            <DropdownOption
                v-if="isForceLogout(row.isBindedToCurrentAdmin)"
                :custom-icon="logoutIcon"
                type="is-danger is-light"
                @click="forceLogoutUser(row)">
                {{ $t(`common.entity.users.buttons.logout`) }}
            </DropdownOption>

            <Permissions
                :permissions="[
                    'WEBMASTERS.BLOCK.ALL',
                    'WEBMASTERS.BLOCK.OWN',
                    'WEBMASTERS.UNBLOCK.ALL',
                    'WEBMASTERS.UNBLOCK.OWN'
                ]"
                :validators="webmasterOwnValidators(row.isBindedToCurrentAdmin)">
                <DropdownOption
                    :icon="blockButtonIcon(row.activity.isBlocked)"
                    :type="blockButtonType(row.activity.isBlocked)"
                    @click="onToggleBlock(row, isSubWebmaster(row.subType))">
                    {{ $t(`admin.users.webmasters.table.buttons.${ row.activity.isBlocked ? 'unblock' : 'block' }`) }}
                </DropdownOption>
            </Permissions>
        </DropdownOptions>

        <Permissions
            v-if="row.isApproved"
            :permissions="['WEBMASTERS.LOGIN.ALL', 'WEBMASTERS.LOGIN.OWN']"
            :validators="webmasterOwnValidators(row.isBindedToCurrentAdmin)">
            <LTooltip
                :label="$t(`admin.users.webmasters.table.buttons.signIn`)"
                position="is-left"
                class="ml-3"
                type="is-success">
                <b-button
                    :loading="isLoading.singInWebmaster && webmasterUserId === row.id"
                    icon-left="sign-in-alt"
                    icon-pack="fas"
                    rounded
                    :disabled="asAdmin"
                    size="is-small"
                    type="is-success is-light"
                    @click="singInAsWebmaster(row.id)">
                </b-button>
            </LTooltip>
        </Permissions>

        <Permissions
            v-else
            :permissions="['WEBMASTERS.APPROVE.ALL', 'WEBMASTERS.APPROVE.OWN']"
            :validators="webmasterOwnValidators(row.isBindedToCurrentAdmin)">
            <LTooltip
                :label="$t(`admin.users.webmasters.table.buttons.approved`)"
                position="is-left"
                class="ml-3"
                type="is-success">
                <b-button
                    :loading="isLoading.approveWebmaster && webmasterUserId === row.id"
                    icon-left="check-circle"
                    icon-pack="far"
                    rounded
                    size="is-small"
                    type="is-success is-light"
                    @click="onApproved(row)">
                </b-button>
            </LTooltip>
        </Permissions>

        <ModalBindUserToAdmin
            v-if="isBindModalVisible"
            :delete-method="DELETE_WEBMASTER_PERSONAL_MANAGER"
            :method="ADD_WEBMASTER_PERSONAL_MANAGER"
            :is-loading="isLoading.bindWebmaster"
            :is-active.sync="isBindModalVisible"
            :user-login="webmasterUserLogin"
            :user-id="webmasterUserId"
            :admin="admin"
            role="webmasters"
            @update="GET_WEBMASTERS">
        </ModalBindUserToAdmin>
    </div>
</template>

<script>
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import ModalBindUserToAdmin from "@/components/Admin/Users/ModalBindUserToAdmin";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import Permissions from "@/components/Common/Permissions.vue";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import {
    DELETE_WEBMASTER_PERSONAL_MANAGER,
    ADD_WEBMASTER_PERSONAL_MANAGER,
    DISABLE_WEBMASTER_PUBLIC_API,
    ENABLE_WEBMASTER_PUBLIC_API,
    GET_WEBMASTER_PROFILE_BY_ID,
    ADMIN_LOGIN_AS_WEBMASTER,
    ADD_APPROVED_WEBMASTER,
    SET_EMPTY_USER_PROFILE,
    RESEND_EMAIL_BY_ADMIN,
    FORCE_LOGOUT_BY_ADMIN,
    UPDATE_MODAL_ACTIVE,
    UNBLOCK_WEBMASTER,
    BLOCK_WEBMASTER,
    GET_WEBMASTERS,
    UPDATE_OPTIONS,
    SET_FORM
  } from "@core/store/action-constants";
  import { actionWithToast } from "@/helpers/actionWithToast";
  import { hasPermissions } from "@core/mixins/permissions";
  import { SET_WEBMASTER_ID } from "@core/store/mutation-constants";
  import logoutIcon from "@/assets/logout.svg";

  export default {
    name: "WebmastersTableOptions",
    mixins: [TableOptionsMixin],
    components: {
      ModalBindUserToAdmin,
      DropdownOptions,
      DropdownOption,
      Permissions
    },

    props: {
      row: {
        type: Object,
        default: () => {}
      }
    },

    data () {
      return {
        webmasterUserLogin: null,
        isBindModalVisible: null,
        webmasterUserId: null,
        admin: null,
        logoutIcon
      };
    },

    computed: {
      ...mapState({
        hasBindAll: ({ auth }) => hasPermissions(["WEBMASTERS.BIND.ALL"], auth.userPermissions),
        hasBindFree: ({ auth }) => hasPermissions(["WEBMASTERS.BIND.FREE"], auth.userPermissions)
      }),

      ...mapState("admin", {
        adminUserIdState: state => state.profile.user.id
      }),

      ...mapGetters(["user", "asAdmin"]),

      ...mapGetters("userLogout", ["isForceLogout"]),

      isLoading () {
        return {
          approveWebmaster: this.$wait(`admin/users/webmasters/${ ADD_APPROVED_WEBMASTER }`),
          bindWebmaster: this.$wait(`admin/users/webmasters/${ ADD_WEBMASTER_PERSONAL_MANAGER }`) ||
            this.$wait(`admin/users/webmasters/${ DELETE_WEBMASTER_PERSONAL_MANAGER }`),
          singInWebmaster: this.$wait(ADMIN_LOGIN_AS_WEBMASTER)
        };
      }
    },

    methods: {
      ...mapActions("admin/users/webmasters", {
        DELETE_WEBMASTER_PERSONAL_MANAGER,
        ADD_WEBMASTER_PERSONAL_MANAGER,
        DISABLE_WEBMASTER_PUBLIC_API,
        ENABLE_WEBMASTER_PUBLIC_API,
        ADD_APPROVED_WEBMASTER,
        RESEND_EMAIL_BY_ADMIN,
        UNBLOCK_WEBMASTER,
        BLOCK_WEBMASTER,
        GET_WEBMASTERS
      }),

      ...mapActions("webmasterDataById/webmasterProfileViewModal", {
        GET_WEBMASTER_PROFILE_BY_ID,
        UPDATE_MODAL_ACTIVE,
        UPDATE_OPTIONS,
        SET_FORM
      }),

      ...mapActions("admin", {
        UPDATE_CREATE_PAYMENT_MODAL_ACTIVE: `finances/costs/createPaymentModal/${ UPDATE_MODAL_ACTIVE }`
      }),

      ...mapActions({
        ADMIN_LOGIN_AS_WEBMASTER,
        SET_EMPTY_USER_PROFILE,
        FORCE_LOGOUT_BY_ADMIN: `userLogout/${ FORCE_LOGOUT_BY_ADMIN }`
      }),

      ...mapMutations("admin/finances/costs/createPaymentModal", {
        SET_WEBMASTER_ID
      }),

      onToggleApi ({ id, isEnableApi }) {
        const dataSet = {
          "true": { action: this.DISABLE_WEBMASTER_PUBLIC_API, title: "disable", toast: "Off" },
          "false": { action: this.ENABLE_WEBMASTER_PUBLIC_API, title: "enable", toast: "On" }
        };

        this.$buefy.dialog.confirm({
          title: this.$t(`admin.users.webmasters.confirm.${ dataSet[isEnableApi].title }PublicApi`),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            await actionWithToast(
              dataSet[isEnableApi].action(id),
              `admin.users.webmasters.messages.toggleApi${ dataSet[isEnableApi].toast }`
            );
          }
        });
      },

      onApproved ({ id, login: userLogin }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.webmasters.confirm.approved"),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            try {
              this.webmasterUserId = id;
              await actionWithToast(
                this.ADD_APPROVED_WEBMASTER(id),
                "admin.users.webmasters.messages.approve",
                {},
                { userLogin }
              );
              await this.badgesStore.GET_BADGES();
            } finally {
              this.webmasterUserId = null;
            }
          }
        });
      },

      bindToSelf ({ id: userId, login }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.webmasters.confirm.bindToSelf.title"),
          message: this.$t("admin.users.webmasters.confirm.bindToSelf.message"),
          confirmText: this.$t("admin.users.webmasters.confirm.bindToSelf.confirm"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-success is-light",
          onConfirm: async () => {
            await actionWithToast(
              this.ADD_WEBMASTER_PERSONAL_MANAGER({ userId, adminIds: this.adminUserIdState }),
              "admin.users.webmasters.messages.bind",
              {},
              { login }
            );
            this.GET_WEBMASTERS();
          }
        });
      },

      async onEditWebmaster (id) {
        // Component: WebmastersProfileViewModal
        this.UPDATE_MODAL_ACTIVE(true);
        this.UPDATE_OPTIONS({ canBeEdit: true });
        await this.GET_WEBMASTER_PROFILE_BY_ID(id);
        this.SET_FORM();
      },

      async resendEmailByAdmin (id) {
        await actionWithToast(
          this.RESEND_EMAIL_BY_ADMIN(id),
          "common.mailConfirmation.resendEmail"
        );
      },

      forceLogoutUser ({ id, login: user }) {
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.common.confirm.logout.title"),
          message: this.$t("admin.users.common.confirm.logout.subtitle", { user }),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          size: "is-small",
          onConfirm: () => actionWithToast(this.FORCE_LOGOUT_BY_ADMIN(id), this.$t("admin.users.common.messages.logout", { user }))
        });
      },

      onToggleBind ({ id, admins, login }) {
        this.webmasterUserId = id;
        this.webmasterUserLogin = login;
        this.admin = admins;
        this.isBindModalVisible = true;
      },

      getBindToManagerLabel (hasAdmin) {
        return hasAdmin
          ? this.$t("admin.users.common.table.buttons.changeManager")
          : this.$t("admin.users.common.table.buttons.bindManager");
      },

      financesCostsCreatePaymentModalOpen (id) {
        this.SET_WEBMASTER_ID(id);
        this.UPDATE_CREATE_PAYMENT_MODAL_ACTIVE(true);
      },

      async singInAsWebmaster (id) {
        try {
          this.webmasterUserId = id;
          await this.ADMIN_LOGIN_AS_WEBMASTER(id);
          this.SET_EMPTY_USER_PROFILE();
        } finally {
          this.webmasterUserId = null;
        }
      },

      bindPermissions () {
        if (this.hasBindAll) {
          return "all";
        } else if (this.hasBindFree && !this.hasBindAll) {
          return "free";
        } else {
          return null;
        }
      },

      isSubWebmaster (subType) {
        return subType === "SUB_WEBMASTER";
      },

      getPublicApiLabel (isEnableApi) {
        return this.$t(`admin.users.webmasters.table.buttons.${ isEnableApi ? "disable" : "enable" }PublicApi`);
      }
    }
  };
</script>

<style lang="scss" scoped>

</style>
