<template>
    <div class="columns is-multiline is-real-mobile-flex is-align-items-flex-end">
        <FilterWrapper wrapper-class="column">
            <CustomDatepicker
                v-model="registeredDatepicker"
                :label="$t(`common.components.datepicker.labels.registrationDate`)">
            </CustomDatepicker>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.approve`)">
                <Select
                    v-model="isApproved"
                    :get-data="getApproved"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)"
                    :searchable="false"
                    class="white"
                    field="label"
                    prop="value">
                </Select>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.emailConfirmed`)">
                <Select
                    v-model="isEmailConfirmed"
                    :get-data="getEmailConfirmed"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)"
                    :searchable="false"
                    class="white"
                    field="label"
                    prop="value">
                </Select>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.skype`)">
                <LInput
                    v-model="skype"
                    :placeholder="$t('admin.users.webmasters.filters.labels.skype')"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.whatsApp`)">
                <LInput
                    v-model.number="whatsApp"
                    :placeholder="$t('admin.users.webmasters.filters.labels.whatsApp')"
                    :onkeypress="validateNumber"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.viber`)">
                <LInput
                    v-model.number="viber"
                    :placeholder="$t('admin.users.webmasters.filters.labels.viber')"
                    :onkeypress="validateNumber"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.domestic`)">
                <Select
                    v-model="isDomestic"
                    class="white"
                    field="label"
                    :searchable="false"
                    prop="value"
                    :get-data="getDomesticWebmasters"
                    :placeholder="$t(`admin.users.webmasters.filters.labels.domestic`)">
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.referral`)">
                <Select
                    v-model="refer"
                    class="white"
                    :searchable="false"
                    field="label"
                    prop="value"
                    :get-data="getReferralList"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)">
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.dateBirthday`)">
                <DateSelect
                    v-model="webmasterBirthday"
                    :placeholder="$t(`admin.users.webmasters.filters.labels.dateBirthday`)"
                    clearable>
                </DateSelect>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.webmasterLabel`)">
                <Select
                    v-model="label"
                    :searchable="false"
                    class="white"
                    field="label"
                    prop="value"
                    :get-data-vuex="getWebmasterLabel"
                    :placeholder="$t(`admin.users.webmasters.filters.labels.webmasterLabel`)">
                </Select>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.team`)">
                <Select
                    v-model="isTeam"
                    :searchable="false"
                    class="white"
                    field="label"
                    prop="value"
                    :get-data-vuex="getValueTeam"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)">
                </Select>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.trafficTypes`)">
                <Select
                    v-model="trafficTypeId"
                    :searchable="false"
                    class="white"
                    multiple
                    :get-data-vuex="getTrafficTypes"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)">
                    <template #text="{ option }">
                        {{ $t(`dictionaries.trafficTypes.${ option.name }`) }}
                    </template>
                    <template #selected-option="{ option }">
                        {{ $t(`dictionaries.trafficTypes.${ option.name }`) }}
                    </template>
                </Select>
            </b-field>
        </FilterWrapper>
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.verticals`)">
                <VerticalsSelect
                    v-model="verticalId"
                    :searchable="false"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)">
                </VerticalsSelect>
            </b-field>
        </FilterWrapper>
    </div>
</template>



<script >
import Select from "@/components/Common/Select/Select.vue";
import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
import DateSelect from "@/components/Common/Date/DateSelect.vue";
import VerticalsSelect from "@/components/Common/Select/VerticalsSelect.vue";
import { mapFields } from "@vasiliyrusin/vue-mapfields";
import { UPDATE_FILTERS } from "@core/store/mutation-constants";
import { mapActions, mapState } from "vuex";
import { GET_APPROVED, GET_DOMESTIC_WEBMASTERS, GET_EMAIL_CONFIRMED, GET_REFERRALS, GET_TRAFFIC_TYPES, GET_WEBMASTER_LABELS } from "@core/store/action-constants";
import webmastersFields from "./datasets/webmastersFields.json";
const __sfc_main = {
  name: "WebmastersFiltersOptions",
  computed: {
    ...mapFields("filtersConfirm", {
      fields: webmastersFields.advancedFilters,
      base: "filters",
      action: UPDATE_FILTERS
    }),
    ...mapState("admin/users/webmasters", {
      approvedList: ({
        approvedList
      }) => approvedList.data,
      domesticWebmastersList: ({
        domesticWebmastersList
      }) => domesticWebmastersList.data,
      emailConfirmedList: ({
        emailConfirmedList
      }) => emailConfirmedList.data,
      referralList: ({
        referralList
      }) => referralList.data
    }),
    ...mapState({
      webmasterLabels: ({
        admin
      }) => admin.webmasterLabels.webmasterLabels,
      trafficTypesList: ({
        trafficTypes
      }) => trafficTypes.trafficTypes
    })
  },
  methods: {
    ...mapActions("admin/users/webmasters", {
      GET_APPROVED: `approvedList/${GET_APPROVED}`,
      GET_DOMESTIC_WEBMASTERS: `domesticWebmastersList/${GET_DOMESTIC_WEBMASTERS}`,
      GET_EMAIL_CONFIRMED: `emailConfirmedList/${GET_EMAIL_CONFIRMED}`,
      GET_REFERRALS: `referralList/${GET_REFERRALS}`
    }),
    ...mapActions({
      GET_WEBMASTER_LABELS: `admin/webmasterLabels/${GET_WEBMASTER_LABELS}`,
      GET_TRAFFIC_TYPES: `trafficTypes/${GET_TRAFFIC_TYPES}`
    }),
    getApproved() {
      this.GET_APPROVED();
      return this.approvedList;
    },
    getEmailConfirmed() {
      this.GET_EMAIL_CONFIRMED();
      return this.emailConfirmedList;
    },
    getDomesticWebmasters() {
      this.GET_DOMESTIC_WEBMASTERS();
      return this.domesticWebmastersList;
    },
    getReferralList() {
      this.GET_REFERRALS();
      return this.referralList;
    },
    getValueTeam() {
      const namespace = "admin.users.webmasters.filters.values";
      const items = [{
        label: this.$t(`${namespace}.team`),
        value: true
      }, {
        label: this.$t(`${namespace}.solo`),
        value: false
      }];
      return {
        items,
        count: items.length
      };
    },
    getWebmasterLabel() {
      this.GET_WEBMASTER_LABELS();
      return this.webmasterLabels;
    },
    async getTrafficTypes() {
      await this.GET_TRAFFIC_TYPES();
      return {
        items: this.trafficTypesList,
        count: this.trafficTypesList?.length
      };
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const validateNumber = "return (event.charCode !== 8 && event.charCode === 0 || event.charCode >= 48 && event.charCode <= 57)";
  return {
    validateNumber
  };
};
__sfc_main.components = Object.assign({
  CustomDatepicker,
  Select,
  DateSelect,
  VerticalsSelect
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped>

</style>