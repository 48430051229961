import { render, staticRenderFns } from "./AccessControlFilter.vue?vue&type=template&id=40161ab0&scoped=true"
import script from "./AccessControlFilter.vue?vue&type=script&lang=js"
export * from "./AccessControlFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40161ab0",
  null
  
)

export default component.exports