<template>
    <div class="columns is-multiline is-real-mobile-flex">
        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.login`)">
                <LInput
                    v-model="loginOrIntId"
                    :placeholder="$t('admin.users.webmasters.filters.labels.login')"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.telegram`)">
                <LInput
                    v-model="telegram"
                    :placeholder="$t('admin.users.webmasters.filters.labels.telegram')"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.email`)">
                <LInput
                    v-model="email"
                    :placeholder="$t('admin.users.webmasters.filters.labels.email')"
                    clearable>
                </LInput>
            </b-field>
        </FilterWrapper>

        <AccessControlFilter
            v-model="manager"
            type="manager"
            route-param="manager"
            :filter-options="{ hasActiveWebmasters: 'true' }"
            :placeholder="$t(`common.entity.filters.admin`)">
        </AccessControlFilter>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.partnerNetwork`)">
                <Select
                    v-model="isPartnerNetwork"
                    :get-data="getPartnerNetwork"
                    :searchable="false"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)"
                    field="label"
                    prop="value">
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.type`)">
                <Select
                    v-model="webmasterSubType"
                    class="white"
                    field="label"
                    :searchable="false"
                    prop="value"
                    :get-data="getWebmasterSubType"
                    :placeholder="$t(`admin.users.webmasters.filters.labels.type`)">
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.blocked`)">
                <Select
                    v-model="isBlocked"
                    :get-data="getBlocked"
                    :searchable="false"
                    :placeholder="$t(`admin.users.webmasters.filters.values.all`)"
                    class="white"
                    field="label"
                    prop="value">
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.residenceCountry`)">
                <CountriesSelect
                    v-model="residenceCountryId"
                    multiple
                    :placeholder="$t(`admin.users.webmasters.filters.labels.residenceCountry`)">
                </CountriesSelect>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.residenceCity`)">
                <CitiesSelect
                    v-model="residenceCityId"
                    multiple
                    :placeholder="$t(`admin.users.webmasters.filters.labels.residenceCity`)">
                </CitiesSelect>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.users.webmasters.filters.labels.availableVertical`)">
                <Select
                    v-model="availableVertical"
                    :searchable="false"
                    :placeholder="$t(`common.auth.signUp.vertical`)"
                    :get-data-vuex="getVerticals">
                    <template #selected-option="{ option }">
                        {{ $t(`common.entity.verticals.${ option.name }`) }}
                    </template>
                    <template #text="{ option }">
                        {{ $t(`common.entity.verticals.${ option.name }`) }}
                    </template>
                </Select>
            </b-field>
        </FilterWrapper>
    </div>
</template>

<script>
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import CitiesSelect from "@/components/Common/Select/CitiesSelect.vue";
  import { mapActions, mapState } from "vuex";
  import { GET_BLOCKED, GET_PARTNER_NETWORK, GET_WEBMASTER_SUBTYPE } from "@core/store/action-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_FILTERS } from "@core/store/mutation-constants";
  import Select from "@/components/Common/Select/Select.vue";
  import webmastersFields from "./datasets/webmastersFields.json";

  export default {
    name: "WebmastersFiltersMain",
    components: { AccessControlFilter, Select, CitiesSelect, CountriesSelect },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: webmastersFields.filters,
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("admin/users/webmasters",{
        partnerNetworkList: ({ partnerNetworkList }) => partnerNetworkList.data,
        referralList: ({ referralList }) => referralList.data,
        blockedList: ({ blockedList }) => blockedList.data,
        webmasterSubTypeData: ({ webmasterSubType }) => webmasterSubType.data
      })
    },

    methods: {
      ...mapActions("admin/users/webmasters", {
        GET_PARTNER_NETWORK: `partnerNetworkList/${ GET_PARTNER_NETWORK }`,
        GET_WEBMASTER_SUBTYPE: `webmasterSubType/${ GET_WEBMASTER_SUBTYPE }`,
        GET_BLOCKED: `blockedList/${ GET_BLOCKED }`
      }),

      getPartnerNetwork () {
        this.GET_PARTNER_NETWORK();
        return this.partnerNetworkList;
      },

      getVerticals () {
        const items = ["NUTRA", "GAMBLING"];
        return { items, count: items.length };
      },

      getWebmasterSubType () {
        this.GET_WEBMASTER_SUBTYPE();
        return this.webmasterSubTypeData;
      },

      getBlocked () {
        this.GET_BLOCKED();
        return this.blockedList;
      }
    }
  };
</script>

<style scoped>

</style>